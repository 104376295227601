<template>
  <div
    class="chat-body"
    @click="clickBody"
  >
    <chat-messages-file-uploader
      v-if="showMediaUploader"
      :files="files"
      @onAdditionalFileChange="onAdditionalFileChange"
      @closeMediaUploader="showMediaUploader = false"
    />

    <perfect-scrollbar
      v-else
      class="custom-messages-scrollbar"
    >
      <div class="scroll-kanban max-height-content h-100">
        <div
          v-if="G_MESSAGES.length > 0"
          ref="scrollList"
          class="scrollList"
          @scroll="handleScroll"
        >
          <template v-for="(message, index) in G_MESSAGES">
            <div
              :key="index"
              style="padding: 1rem 1rem; border-radius: 10px"
              :class="{
                me: message.created_by == currentUser.user_id,
                other: message.created_by != currentUser.user_id,
                center: message.created_by == 0,
              }"
              @mouseover="showReactionIcon(index, true)"
              @mouseout="showReactionIcon(index, false)"
            >
              <b-container
                class="d-flex align-items-center justify-content-between"
              >
                <b-avatar
                  v-if="message.created_by != currentUser.user_id"
                  :src="message.user_photo"
                  size="3.5rem"
                />

                <div
                  :ref="'div' + message.message_id"
                  class="d-flex flex-column"
                  :class="{
                    'justify-content-end align-items-end mr-1':
                      message.created_by == currentUser.user_id,
                    'justify-content-start align-items-start ml-1':
                      message.created_by != currentUser.user_id,
                  }"
                >
                  <span style="font-size: 1em; font-weiht: bolder">
                    {{
                      message.created_by == currentUser.user_id
                        ? "You"
                        : message.sent_by
                    }}

                    <span
                      style="
                          font-size: 1em;
                          font-weiht: bolder;
                          margin-left: 1.5rem;
                        "
                    >{{ message.sent_at | myHourTime }}</span>
                  </span>
                  <div
                    class="d-flex"
                    :class="
                      message.files && message.files != null
                        ? 'flex-column-reverse'
                        : 'flex-column'
                    "
                  >
                    <!-- show simple message -->
                    <span
                      v-if="message.content != null && message.files == null"
                      :ref="'span' + message.message_id"
                      style="margin-top: 0.5rem"
                      :class="{
                        'message-content-me mr-1':
                          message.created_by == currentUser.user_id,
                        'message-content-other ':
                          message.created_by != currentUser.user_id,
                      }"
                      v-html="
                        highlightMessages(
                          message.content,
                          message.message_id == searchMessageId
                            ? searchMessage
                            : null,
                          10
                        )
                      "
                    /> 
                    <!-- seens and reactions -->
                    <div
                      class="d-flex align-items-center"
                      :class="{
                        'justify-content-between':
                          seens.length > 0 &&
                          G_MESSAGES[0].message_id == message.message_id,
                      }"
                    >
                      <template v-if="seens.length > 0">
                        <div
                          v-if="
                            G_MESSAGES[0].message_id == message.message_id &&
                            seens.length <= 5
                          "
                          class="avatar-seen-container"
                        >
                          <template v-for="(seen, index) in seens">
                            <b-avatar
                              v-if="seen.user_id != currentUser.user_id && seen.user_id != message.created_by && viewSeen"
                              size="1.4rem"
                              class="avatar-seen cursor-pointer"
                              v-b-tooltip.hover.top
                              :title="seen.user"
                              :key="index"
                              :src="seen.user_photo"
                            ></b-avatar>
                          </template>
                        </div>
                      </template>

                      <!-- reactions here -->
                      <chat-messages-reaction
                        v-if="message.reactions != null"
                        :message="message"
                        :index="index"
                      />
                    </div>

                    <!-- show resources  -->
                    <div
                      v-if="message.files && message.files != null"
                      class="image-grid"
                    >
                      <template v-for="(file, index) in message.files">
                        <template
                          v-if="validImageExtensions.includes(file.extension)"
                        >
                          <div
                            v-if="index <= 3"
                            :key="index"
                            :class="{
                              'img-overlay':
                                index === 3 && message.files.length > 4,
                            }"
                            class="image-container cursor-pointer"
                            :style="
                              message.files.length <= 4
                                ? 'padding: 0.3rem 0.5rem'
                                : ''
                            "
                            @click="previewImage(message.files, file.route)"
                          >
                            <b-img-lazy
                              class="image-container-image"
                              v-bind="imageProps"
                              :src="file.route"
                              :alt="file.name"
                            />

                            <div
                              v-if="index === 3 && message.files.length > 4"
                              class="overlay-text"
                            >
                              +{{ message.files.length - 4 }}
                            </div>
                          </div>
                        </template>

                        <template v-else-if="file.extension == 'mp3'">
                          <audio
                            :key="file.filename"
                            controls
                            class="mt-1"
                          >
                            <source
                              :src="file.route"
                              type="audio/mp3"
                            >
                            /> Your browser does not support the audio element.
                          </audio>
                        </template>

                        <template v-else-if="file.extension == 'mp4'">
                          <span
                            :key="index"
                            style="margin-top: 0.5rem"
                            :class="{
                              'message-content-me mr-1':
                                message.created_by == currentUser.user_id,
                              'message-content-other ':
                                message.created_by != currentUser.user_id,
                            }"
                          >
                            <video
                              style="
                                  width: 100%;
                                  height: 100%;
                                  border-radius: 5%;
                                "
                              controls
                              :src="file.route"
                            />
                          </span>
                        </template>
                        <template v-else>
                          <span
                            :key="index"
                            class="cursor-pointer"
                            style="margin-top: 0.5rem"
                            :class="{
                              'message-content-me mr-1':
                                message.created_by == currentUser.user_id,
                              'message-content-other ':
                                message.created_by != currentUser.user_id,
                            }"
                            @click="forceDownload(file.route, file.name)"
                          >
                            <p-d-f-svg
                              v-if="file.extension === 'pdf'"
                              style="width: 2rem; height: 2rem"
                            />
                            <feather-icon
                              v-else
                              icon="FileIcon"
                              size="16"
                            />

                            <span> {{ file.filename | limitChars(15) }} </span>
                            <span> {{ file.size }} </span>
                            <feather-icon
                              icon="DownloadIcon"
                              size="16"
                            />
                          </span>
                        </template>
                      </template>
                    </div>
                  </div>
                </div>

                <b-avatar
                  v-if="message.created_by == currentUser.user_id"
                  :src="message.user_photo"
                  size="3.5rem"
                />

                <!-- <chat-message-reaction-selector
                  v-if="message.created_by != currentUser.user_id"
                  :index="index"
                  :message="message"
                /> -->
              </b-container>
            </div>
          </template>
          <transition name="fade">
            <span
              v-if="showScrollDown"
              class="scroll-down"
            >
              <b-button @click="scrollCommentsBottom()">
                <feather-icon
                  icon="ArrowDownIcon"
                  size="20"
                />
              </b-button>
            </span>
          </transition>
        </div>
        <div
          v-else
          class="d-flex justify-content-center align-items-center h-100"
        >
          No messages yet
        </div>
      </div>
    </perfect-scrollbar>

    <image-viewer
      v-if="showImageViewer"
      :images="currentFiles"
      @hidden="showImageViewer = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import PerfectScrollbar from 'vue-perfect-scrollbar';
import ImageViewer from '@/commons/ImagePreview.vue';
import BinnacleService from "@/views/commons/components/reports/services/transaction-binnacle.service.js";
// import ChatMessageReactionSelector from '@/views/commons/components/paragon-soft/components/binnacle/ChatMessageReactionSelector.vue';
import ChatMessagesFileUploader from '@/views/commons/components/paragon-soft/components/binnacle/ChatMessagesFileUploader.vue';
import { highlightLetters } from '@/helpers/highlightText.js';
import ChatMessagesReaction from '@/views/commons/components/reports/binnacle/ChatMessagesReaction.vue';
import PDFSvg from '@/assets/images/icons/request-chat/pdf.vue';

export default {
  name: 'ChatMessages',
  components: {
    PerfectScrollbar,
    ImageViewer,
    // ChatMessageReactionSelector,
    ChatMessagesFileUploader,
    ChatMessagesReaction,
    PDFSvg,
  },
  props: {
    files: {
      type: Array,
      required: true,
    },
    transactionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showMediaUploader: false,
      validImageExtensions: ['png', 'jpg', 'jpeg', 'gif', 'svg'],
      imageProps: {
        center: true,
        fluidGrow: false,
        blank: true,
        blankColor: '#bbb',
        width: 150,
        height: 150,
      },
      showImageViewer: false,
      currentFiles: null,
      seens: [],
      initialScrollTop: 0,
      pageNumber: 1,
      messageLength: 0,
      showScrollDown: false,
      currentFileRoute: '',
      searchMessage: '',
      searchMessageId: null,
      viewSeen:false,
    };
  },
  async created() {
    await this.bindNewMessage();
    await this.bindNewMessageSeen();
    await this.bindNewMessageReaction();
  },
  mounted() {
    this.getBinnacleMessages();
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      G_CURRENT_CHAT_ID: 'ChatReportStore/G_CURRENT_CHAT_ID',
      G_MESSAGES: 'ChatReportStore/G_MESSAGES',
      G_CURRENT_CHAT_MEMBERS:
          'ChatReportStore/G_CURRENT_CHAT_MEMBERS',
      G_EMOJIS: 'ChatReportStore/G_EMOJIS',
    }),
  },

  watch: {
    G_CURRENT_CHAT_ID(newCurrentChatId) {
      if (newCurrentChatId) {
        this.getBinnacleMessages();
      }
    },
    files: {
      handler(val) {
        if (val.length > 0) {
          this.showMediaUploader = true;
        } else {
          this.showMediaUploader = false;
        }
      },
      deep: true,
    },
  },
  beforeDestroy() {
    try {
      window.socket.unbind('transaction-binnacle-new-message');
      window.socket.unbind('transaction-binnacle-new-message-seen');
      window.socket.unbind('transaction-binnacle-new-message-reaction');
    } catch (error) {
      console.log('transaction-binnacle-new-message', error);
      console.log('transaction-binnacle-new-message-seen', error);
      console.log('transaction-binnacle-new-message-reaction', error);
    }
  },
  methods: {
    onAdditionalFileChange(file) {
      this.$emit('onAdditionalFileChange', file);
    },
    showReactionIcon(index, visible) {
      if (!this.G_MESSAGES[index].show_emojis) {
        this.G_MESSAGES.forEach(message => {
          message.show = false;
          message.show_emojis = false;
        });

        this.G_MESSAGES[index].show = visible;
      }
    },
    ...mapActions({
      A_GET_MESSAGES: 'ChatReportStore/A_GET_MESSAGES',
    }),
    ...mapMutations({
      M_SET_USER_PHOTO: 'ChatReportStore/M_SET_USER_PHOTO',
      M_SET_REACTIONS_IN_MESSAGE_BY_ID:
          'ChatReportStore/M_SET_REACTIONS_IN_MESSAGE_BY_ID',
    }),
    scrollToDiv(div) {
      div.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },

    addClassToElementByRef(element) {
      element.classList.add('highlight-text');
    },

    removeAllClassToElements() {
      const elements = document.getElementsByClassName('highlight-text');
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove('highlight-text');
      }
    },

    goToSelectedMessage(message) {
      this.searchMessage = message.search_message;
      this.searchMessageId = message.id; 
      setTimeout(() => {
        this.$nextTick(() => {
          let result = this.G_MESSAGES.filter(
            (obj) => obj.message_id === message.id
          );

          if (result.length > 0) {
            let div = this.$refs["div" + message.id];
            let span = this.$refs["span" + message.id];
            if (div) {
              this.scrollToDiv(div[0]);
              this.removeAllClassToElements();
              this.addClassToElementByRef(span[0]);
            }
          } else {
            this.$refs.scrollList.scrollTop =
              this.$refs.scrollList.scrollHeight * -1;

            this.$nextTick(() => {
              let div = this.$refs["div" + message.id];
              let span = this.$refs["span" + message.id];
              if (div) {
                this.scrollToDiv(div[0]);
                this.removeAllClassToElements();
                this.addClassToElementByRef(span[0]);
              }
            });
          }
        });
      }, 500);
    },
    getUserImage(user_id) {
      const user = this.G_CURRENT_CHAT_MEMBERS.find(
        participant => participant.user_id === user_id,
      );
      return user.user_photo;
    },
    previewImage(files, route) {
      const newFiles = files
        .map(file => file.route)
        .filter(file => file != route);
      newFiles.unshift(route);
      this.showImageViewer = true;
      this.currentFiles = newFiles;
    },

    scrollCommentsBottom() {
      this.$nextTick(() => {
        if (this.$refs.scrollList) {
          this.$refs.scrollList.scrollTop = this.$refs.scrollList.scrollHeight;
          this.initialScrollTop = this.$refs.scrollList.scrollTop;
        }
      });
    },
    /**
       * Verify if current user saw the incoming message
       */
    verifySeen() {
      let index = this.seens.findIndex(
        (seen) => seen.user_id == this.currentUser.user_id
      );
      if (index == -1) {
        return false;
      }
      return true;
    },

    async storeBinnacleMessageSeen() {
      try {
        const params = {
          binnacle_id: this.G_CURRENT_CHAT_ID,
          user_id: this.currentUser.user_id,
        };
        await BinnacleService.storeBinnacleMessageSeen(params);
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    getAvatarFromMembers(user_id) {
      const user = this.G_CURRENT_CHAT_MEMBERS.find(
        participant => participant.user_id === user_id,
      );
      return user?.user_photo;
    },

    async bindNewMessage() { 
      try {
        window.socket.bind(
          'transaction-binnacle-new-message',
          async ({ message, allowUsers }) => { 
            if (
              allowUsers.includes(this.currentUser.user_id)
                && this.G_CURRENT_CHAT_ID == message.binnacle_id
            ) {
              this.viewSeen = false;
              message.user_photo = this.getAvatarFromMembers(
                message.created_by,
              ); 
              this.G_MESSAGES.unshift(message);
              this.scrollCommentsBottom();
              const userSawIncomingMessage = this.verifySeen();  
              if (userSawIncomingMessage) {  
                this.storeBinnacleMessageSeen();
              }
              this.viewSeen = true;
            }
          },
        );
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async bindNewMessageSeen() {
      try {
        window.socket.bind(
          'transaction-binnacle-new-message-seen',
          async ({ data, binnacle_id }) => {
            const { allow_users } = data;
            if (
              allow_users.includes(this.currentUser.user_id)
                && this.G_CURRENT_CHAT_ID == binnacle_id
            ) {
              this.seens = [];
              const incoming_seens = JSON.parse(data.seens);
              this.setAvatar(incoming_seens);
              this.seens = incoming_seens;
            }
          },
        );
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async bindNewMessageReaction() {
      try {
        window.socket.bind(
          'transaction-binnacle-new-message-reaction',
          async ({ content }) => {
            if (content != null) {
              const incoming_reactions = JSON.parse(content[0].reactions);
              const isCurrentUserAllowed = this.G_CURRENT_CHAT_MEMBERS.some(
                user => user.user_id === this.currentUser.user_id,
              );
              if (isCurrentUserAllowed) {
                const { message_id } = content[0];
                this.M_SET_REACTIONS_IN_MESSAGE_BY_ID({
                  message_id,
                  reactions: incoming_reactions,
                });
              }
            }
          },
        );
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    setAvatar(array) {
      array.forEach(seen => {
        const participant_index = this.G_CURRENT_CHAT_MEMBERS.findIndex(
          participant => participant.user_id === seen.user_id,
        );
        if (participant_index !== -1) {
          const { user_photo } = this.G_CURRENT_CHAT_MEMBERS[participant_index];
          if (user_photo) {
            seen.user_photo = user_photo;
          }
        }
      });
    },

    async getBinnacleMessages(previousMessages = false) {
      try {
        this.addPreloader();
        this.pageNumber = previousMessages ? this.pageNumber : 1;
        const params = {
          transaction_id: this.transactionId,
          page_number: this.pageNumber,
          page_size: 100,
          user_id: this.currentUser.user_id,
        };
        const data = await this.A_GET_MESSAGES(params);
        if (data.length === 0 && previousMessages) {
          return;
        }
        if (data.length > 0) {
          this.seens = data[0].seens ?? [];
          this.setAvatar(this.seens);
        }
        previousMessages ?? this.scrollCommentsBottom();
        this.M_SET_USER_PHOTO(this.G_CURRENT_CHAT_MEMBERS);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    clickBody() {
      this.$emit('onClickMessages');
    },

    forceDownload(url, filename) {
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handleScroll(event) {
      const scrollContainer = event.target;
      const scrollClientHeight = scrollContainer.clientHeight;
      const { scrollHeight } = scrollContainer;
      const { scrollTop } = scrollContainer;
      const currentScrollTop = Math.abs(scrollTop) + scrollClientHeight + this.initialScrollTop;
      const scrollUp = scrollHeight - currentScrollTop;
      if (scrollUp >= 0 && scrollUp <= 1 && scrollTop < 0) {
        this.pageNumber += 1;
        // this.getBinnacleMessages(true);
      }
      if (scrollTop < 0) {
        this.showScrollDown = true;
      } else {
        this.showScrollDown = false;
      }
    },
    highlightMessages(text, searchMessage, lengthText) {
      return highlightLetters(text, searchMessage, lengthText);
    },
  },
};
</script>

  <style lang="scss" scoped>
  .chat-body {
    margin: 5px;
    position: relative;
    max-height: 100%;

    // .highlight-text {
    //   background-color: rgb(255, 255, 0) !important;
    //   color: #040d19 !important;
    // }
    .custom-messages-scrollbar {
      margin-top: 0.2rem;
      height: 66vh;
    }
    .message-content-other {
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      border-bottom-right-radius: 16px;
      color: #010101;
      padding: 0.7rem 1rem;
      background-color: #f2f2f2;
      word-wrap: break-word;
      overflow-wrap: anywhere;
    }
    .message-content-me {
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      color: #eff3f8;
      padding: 0.7rem 1rem;
      background-color: #14b8a6;
      word-wrap: break-word;
      overflow-wrap: anywhere;
    }

    .image-grid {
      display: flex;
      flex-wrap: wrap;
      max-width: 320px;
      padding: 0rem 0.3rem !important;
    }
    .image-container {
      width: 50%;
      box-sizing: border-box;
      padding: 0.5rem 0rem;
      position: relative;
      &.img-overlay {
        .image-container-image {
          filter: brightness(30%);
        }
      }
    }
    .image-container-image {
      border-top-right-radius: 15%;
      border-top-left-radius: 15%;
      border-bottom-left-radius: 15%;
      border-bottom-right-radius: 15%;
      object-fit: cover;
    }
    .scrollList {
      display: flex;
      flex-direction: column-reverse;
      position: relative;
      height: 66vh;
      scroll-behavior: smooth !important;
      overflow-y: scroll;
    }
    .overlay-text {
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translate(-50%, -50%);
      bottom: 0;
      right: 0;
      color: #f2f2f2;
      font-size: 2.2rem;
      font-weight: 800;
    }

    .me {
      margin-left: auto;
      .avatar-seen-container {
        margin-top: 0.7rem;
        .avatar-seen {
          margin: 0 0.2rem;
        }
      }
    }
    .other {
      margin-right: auto;
      .avatar-seen-container {
        margin-top: 0.7rem;
        // margin-left: 5.5rem;
        .avatar-seen {
          margin: 0 0.2rem;
        }
      }
    }
    .center {
      margin: 0 auto;
    }
    .scroll-down {
      position: absolute;
      right: 3.5rem;
      bottom: 6rem;
      margin: 1rem;

      button {
        position: fixed;
        background-color: #14b8a6 !important;
        border-radius: 50%;
        border: none;
        padding: 1rem;
      }
    }
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.5s;
    }
    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }
  }
  .dark-layout {
    .chat-body {
      .message-content-other {
        background-color: rgba(255, 255, 255, 0.03);
        color: rgba(255, 255, 255, 0.6);
      }
      .message-content-me {
        background-color: #5eead4;
        color: #040d19;
      }
      .b-avatar {
        background-color: #0b213f !important;
      }

      .scroll-down {
        button {
          background-color: #5eead4 !important;
          svg.feather.feather-arrow-down {
            color: #040d19;
          }
        }
      }
    }
  }
  </style>
