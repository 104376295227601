<template>
    <div
      class="message-reactions"
      :class="{
        'mr-1': message.created_by == currentUser.user_id,
        '': message.created_by != currentUser.user_id,
      }"
      @click="showReactionList(index)"
    >
      <b-img
        v-for="(reaction, index) in getUniqueReactions(message.reactions)"
        :key="index"
        class="message-reaction"
        height="15"
        width="15"
        :src="require(`@/assets/images/icons/soft-projects/${reaction.reaction}`)"
      ></b-img>
  
      <div
        class="reaction-list"
        :class="{
          'reaction-list-me': message.created_by == currentUser.user_id,
          'reaction-list-other': message.created_by != currentUser.user_id,
        }"
        v-if="G_MESSAGES[index].show_reactions && message.reactions.length > 0"
      >
        <div class="d-flex align-items-center justify-content-between mb-1">
          <span> Total {{ message.reactions.length }} </span>
  
          <div
            class="mx-1"
            v-for="(reaction, index) in getUniqueReactions(message.reactions)"
            :key="`reaction-resume-${index}`"
          >
            <b-img
              height="15"
              width="15"
              :src="
                require(`@/assets/images/icons/soft-projects/${reaction.reaction}`)
              "
            ></b-img>
            <span>
              {{
                getReactionsCountByReactionId(
                  message.reactions,
                  reaction.reaction_id
                )
              }}
            </span>
          </div>
        </div>
        <div
          class="d-flex align-items-center justify-content-between my-1"
          v-for="(reaction, index) in message.reactions"
          :key="`reaction-list-${index}`"
        >
          <div>
            <b-avatar
              class="mr-1"
              size="2.5rem"
              :src="getAvatarFromMembers(reaction.user_id)"
            ></b-avatar>
  
            <span class="mr-1"> {{ reaction.user }} </span>
          </div>
  
          <b-img
            class="message-reaction"
            height="22"
            width="22"
            :src="
              require(`@/assets/images/icons/soft-projects/${reaction.reaction}`)
            "
          ></b-img>
        </div>
      </div>
  
      {{ message.reactions.length }}
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  export default {
    props: {
      message: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
    },
    computed: {
      ...mapGetters({
        currentUser: "auth/currentUser",
        G_MESSAGES: "ChatReportStore/G_MESSAGES",
        G_CURRENT_CHAT_MEMBERS:
          "ChatReportStore/G_CURRENT_CHAT_MEMBERS",
      }),
    },
  
    methods: {
      showReactionList(index) {
        this.G_MESSAGES[index].show_reactions =
          !this.G_MESSAGES[index].show_reactions;
      },
      getUniqueReactions(reactions) {
        const uniqueReactions = [];
        const reactionSet = new Set();
  
        reactions.forEach((reaction) => {
          if (!reactionSet.has(reaction.reaction)) {
            reactionSet.add(reaction.reaction);
            uniqueReactions.push(reaction);
          }
        });
  
        return uniqueReactions;
      },
      getReactionsCountByReactionId(ractions, reaction_id) {
        const reactions = ractions.filter(
          (reaction) => reaction.reaction_id === reaction_id
        );
        return reactions.length;
      },
      getAvatarFromMembers(user_id) {
        const user = this.G_CURRENT_CHAT_MEMBERS.find(
          (participant) => participant.user_id === user_id
        );
        return user.user_photo;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .reaction-list {
    position: absolute !important;
    background-color: #fff;
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;
    border-width: 0 20px 20px 0;
    width: max-content !important;
    height: 150px !important;
    overflow-y: scroll !important;
  }
  
  .reaction-list-other {
    margin-left: 20%;
    margin-top: -10%;
  }
  .reaction-list-me {
    margin-left: -20%;
    margin-top: -10%;
  }
  
  .message-reactions {
    background-color: #f2f2f2;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    color: #6e6b7b !important;
  
    margin-top: 0.2rem;
    border-radius: 1rem;
    padding: 0.5rem;
  }
  
  .message-reaction {
    margin: 0rem 0.2rem;
  }
  
  .dark-layout {
    .message-reactions {
      background-color: #0c1420 !important;
      color: rgba(255, 255, 255, 0.6) !important;
    }
    .reaction-list {
      background-color: #0c1420 !important;
    }
  }
  </style>