<template>
  <b-modal
    @hidden="$emit('close')"
    v-model="show"
    :title="
      type === 'photos'
        ? 'All photos'
        : type === 'members'
        ? 'Members'
        : 'All files'
    "
    title-class="text-white h3 font-weight-bold"
    hide-footer
    :size="type === 'photos' ? 'xl' : 'lg'"
    scrollable
  >
    <b-row v-if="type === 'photos'">
      <b-col cols="4" v-for="(image, index) in photos" :key="index" class="p-2">
        <b-img
          @click="forceDownload(image.route, image.filename)"
          class="cursor-pointer"
          :src="image.route"
          :alt="image.filename"
          style="
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-height: 380px;
            max-width: 650px;
            border-radius: 0.5rem;
          "
        ></b-img>
        <div class="d-flex justify-content-between align-items-center">
          <span class="text-center">{{
            image.created_by | limitChars(25)
          }}</span>
          <span class="text-center">{{
            image.created_at | myGlobalWithHour
          }}</span>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="type == 'members'">
      <!-- list of files  -->
      <b-col
        cols="12"
        v-for="(member, index) in G_CURRENT_CHAT_MEMBERS"
        :key="index"
      >
        <b-list-group>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <div>
              <b-avatar
                :key="index"
                :src="`${member.user_photo}`"
                size="3.5rem"
                class="mr-2"
              ></b-avatar>
              <span class="member-name">{{ member.user }}</span>
            </div>

            <div class="ml-2">
              <feather-icon
                @click="removeMember(member)"
                icon="TrashIcon"
                size="20"
                class="cursor-pointer text-danger"
              />
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row v-if="type == 'files'">
      <!-- list of files  -->
      <b-col cols="12" v-for="(file, index) in files" :key="index">
        <b-list-group>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
            <div class="d-flex align-items-start flex-column">
              <div>
                <PDFSvg
                  v-if="file.extension === 'pdf'"
                  style="width: 20px; height: 20px; margin-right: 5px"
                />
                <feather-icon
                  v-else-if="file.extension == 'mp3'"
                  icon="MusicIcon"
                  size="20"
                  class="mr-2"
                ></feather-icon>

                <feather-icon
                  v-else-if="file.extension == 'mp4'"
                  icon="VideoIcon"
                  size="20"
                  class="mr-2"
                ></feather-icon>

                <feather-icon
                  v-else
                  icon="FileIcon"
                  size="20"
                  class="mr-2"
                ></feather-icon>

                <span class="text-left">{{ file.filename }}</span>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <span class="ml-3">{{ file.created_by | limitChars(50) }}</span>
                <span class="ml-5">{{
                  file.created_at | myGlobalWithHour
                }}</span>
              </div>
            </div>

            <div class="ml-2">
              <b-button
                variant="primary"
                @click="forceDownload(file.route, file.filename)"
                >Download</b-button
              >
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import PDFSvg from "@/assets/images/icons/request-chat/pdf.vue";
import { mapGetters } from "vuex";
import BinnacleService from "@/views/commons/components/reports/services/transaction-binnacle.service.js";

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  components: {
    PDFSvg,
  },
  data() {
    return {
      show: true,
      photos: [],
      files: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_CURRENT_CHAT_ID: "ChatReportStore/G_CURRENT_CHAT_ID",
      G_CURRENT_CHAT_MEMBERS:
        "ChatReportStore/G_CURRENT_CHAT_MEMBERS",
    }),
  },
  created() {
    this.getBinnacleFiles();
  },
  methods: {
    forceDownload(url, filename) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async getBinnacleFiles() {
      try {
        this.addPreloader();
        const params = {
          binnacle_id: this.G_CURRENT_CHAT_ID,
          limit: 0,
        };
        const response = await BinnacleService.getBinnacleFiles(params);
        if (this.type === "photos") {
          this.photos = response.photos || [];
        } else {
          this.files = response.files || [];
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async removeMember(member) {
      try {
        const result = await this.showConfirmSwal();
        if (!result.isConfirmed) {
          return;
        }
        this.addPreloader();
        const params = {
          binnacle_id: this.G_CURRENT_CHAT_ID,
          user_id: member.user_id,
        };
        await BinnacleService.removeMember(params);
        const index = this.G_CURRENT_CHAT_MEMBERS.findIndex(
          (item) => item.user_id === member.user_id
        );
        this.G_CURRENT_CHAT_MEMBERS.splice(index, 1);
        this.showGenericToast({
          title: "Success",
          text: "Member removed successfully",
          variant: "success",
        });
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.member-name {
  font-size: 0.8rem;
  font-weight: 500;
  color: #000;
}
