<template>
  <b-modal
    ref="chat-modal"
    v-model="showModal"
    body-class="px-1 py-0"
    :header-class="modalColor"
    size="xlg"
    hide-footer
    centered
    no-close-on-backdrop
    no-close-on-esc
    @hidden="close"
  >
    <template #modal-header>
      <span class="chat-modal-title">{{ getModalTitle }}</span>
      <feather-icon
        v-if="currentUser.user_id != 402"
        icon="XIcon"
        size="20"
        class="ml-auto cursor-pointer text-light"
        @click="showModal = false"
      />
      <div
        v-else
        class="ml-auto cursor-pointer"
        @click="showModal = false"
      >
        <icon />
      </div>
    </template>

    <b-row class="request-chat-container h-100">
      <b-col
        cols="12"
        class="p-0"
      >
        <chat-navbar
          @toggleSidebar="toggleSidebar"
          @toggleSearchSidebar="toggleSearchSidebar"
        />
        <chat-messages
          ref="chat-messages"
          :files="send.files"
          :transaction-id="transactionId"
          @onAdditionalFileChange="onAdditionalFileChange"
          @onClickMessages="onClickMessages"
        />
        <chat-footer
          ref="chat-footer"
          :transaction-id="transactionId"
          @onFileChange="onFileChange"
          @onNewMessage="onNewMessage"
        />
        <chat-sidebar
          v-if="sidebarVisible"
          ref="chat-sidebar"
          @toggleSidebar="toggleSidebar"
        />
        <chat-search-sidebar
          v-if="searchSidebarVisible"
          @toggleSearchSidebar="toggleSearchSidebar"
          @goToSelectedMessage="goToSelectedMessage"
          @closeSearchSidebar="closeSearchSidebar"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
// import ChatList from '@/views/commons/components/paragon-soft/components/binnacle/ChatList.vue';
import ChatNavbar from '@/views/commons/components/reports/binnacle/ChatNavbar.vue';
import ChatSidebar from '@/views/commons/components/reports/binnacle/ChatSidebar.vue';
// import ChatSearchbar from '@/views/commons/components/paragon-soft/components/binnacle/ChatSearchBar.vue';
import ChatMessages from '@/views/commons/components/reports/binnacle/ChatMessages.vue';
import ChatFooter from '@/views/commons/components/reports/binnacle/ChatFooter.vue';
// import MatchedMessagesList from '@/views/commons/components/paragon-soft/components/binnacle/MatchedMessagesList.vue';
import ChatSearchSidebar from '@/views/commons/components/reports/binnacle/ChatSearchSidebar.vue';
import ChatTransactions from '@/views/commons/components/reports/services/chat-transactions.service';
import Icon from '@/assets/images/icons/request-chat/good.vue';

export default {
  components: {
    // ChatList,
    ChatNavbar,
    ChatSidebar,
    // ChatSearchbar,
    ChatMessages,
    ChatFooter,
    // MatchedMessagesList,
    ChatSearchSidebar,
    Icon,
  },
  props: {
    binnacleId: {
      type: Number
    },
    transactionId: {
      type: String,
      required: true,
    },
    transactionClient: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: true,
      sidebarVisible: false,
      searchSidebarVisible: false,
      currentChatInfo: {},
      send: {
        message: '',
        files: [],
      },
      matchedMessages: null,
      modalColor: 'bg-primary',
    };
  },
  async created() {
    this.initWebsocket();
    const params = {
      id: this.transactionId,
      active: 1,
      user_id: this.currentUser.user_id,
    };
    const data = await ChatTransactions.createTransactionBinnacle(params);
    const binnacleId = data.binnacle_id;
    this.M_SET_CURRENT_CHAT_ID(binnacleId);

    this.M_SET_CURRENT_CHAT_HEADER({
      transaction_id: this.transactionId,
      transaction_client: this.transactionClient,
    });
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    getModalTitle() {
      return `CHAT`;
    },
  },
  async mounted() {
    this.toggleModal('chat-modal');
  },

  methods: {
    ...mapActions({
      A_GET_EMOJIS: 'ChatReportStore/A_GET_EMOJIS',
      A_GET_CHAT_MEMBERS: 'ChatReportStore/A_GET_CHAT_MEMBERS',
      A_GET_TOTAL_MESSAGES: 'ChatReportStore/A_GET_TOTAL_MESSAGES',
    }),
    ...mapMutations({
      M_SET_CURRENT_CHAT_ID: 'ChatReportStore/M_SET_CURRENT_CHAT_ID',
      M_SET_CURRENT_CHAT_HEADER: 'ChatReportStore/M_SET_CURRENT_CHAT_HEADER',
    }),
    initWebsocket() {
      window.socket.unsubscribe('transaction-binnacle-chat');
      window.socket.subscribe('transaction-binnacle-chat');
    },
    clearSearch() {
      this.matchedMessages = null;
    },
    async goToSelectedMessage(message) {
      await this.A_GET_CHAT_MEMBERS({
        binnacle_id: message.binnacle_id,
      });
      // this.getModalColor(message.type);
      this.$refs['chat-messages'].goToSelectedMessage(message);
      this.$refs['chat-messages'].goToSelectedMessage(message);
    },
    searchMessage(matchedMessages) {
      this.matchedMessages = matchedMessages;
    },
    onClickMessages() {
      if (this.$refs['chat-footer']) {
        this.$refs['chat-footer'].hideEmojis();
      }
      this.sidebarVisible = false;
      this.searchSidebarVisible = false;
    },
    onNewMessage() {
      this.send.files = [];
    },
    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible;
    },
    closeSearchSidebar() {
      this.searchSidebarVisible = false;
    },
    toggleSearchSidebar() {
      this.searchSidebarVisible = !this.searchSidebarVisible;
    },
    onAdditionalFileChange(files) {
      this.send.files.push(files);
    },
    close() {
      this.$emit('hidden');
    },
    onFileChange(files) {
      this.send.files = files;
    },
  },
};
</script>

  <style lang="scss" scoped>
  .chat-modal-title {
    font-weight: 800;
    font-size: 1.3rem;
    color: #ffffff;
  }
  .request-chat-container {
    .title-messages {
      font-weight: 500;
      color: #0b213f;
    }

    .left-content {
      border-right: 2px solid #e5e5e5;
    }
  }
  .dark-layout {
    .request-chat-container {
      background-color: #040d19;

      .left-content {
        border-right: 2px solid #0b213f;
      }
    }
    .chat-modal-title {
      color: #0b213f;
    }
  }
  </style>
