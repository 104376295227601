<template>
  <div class="d-flex align-items-center w-100">
    <feather-icon
      v-if="searchText"
      icon="ArrowLeftIcon"
      size="20"
      class="ml-2 cursor-pointer p-0"
      @click="clearSearch()"
    />
    <div class="chat-search-bar">
      <b-input-group class="pb-1 px-2 pt-2">
        <b-input-group-prepend class="br-left-5">
          <span
            class="input-group-text bg-dark-gray cursor-pointer"
            @click="search()"
            ><feather-icon icon="SearchIcon" size="19" class="gray" />
          </span>
        </b-input-group-prepend>
        <b-form-input
          v-model="searchText"
          class="without-left-border br-right-5 placeholder-gray bg-dark-gray px-0"
          placeholder="Search for anything"
          @keyup.enter="search()"
        >
        </b-form-input>
      </b-input-group>
    </div>
  </div>
</template> <script>
import { mapGetters } from "vuex";
import BinnacleService from "@/views/commons/components/reports/services/transaction-binnacle.service.js";

export default {
  props: {
    searchInCurrentChat: {
      type: Boolean,
      required: true,
    },
  },
  name: "ChatSearchBar",
  data() {
    return { searchText: "" };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_CURRENT_CHAT_ID: "ChatReportStore/G_CURRENT_CHAT_ID",
    }),
  },
  watch: {
    searchText: {
      handler: function (val) {
        if (val == "") {
          this.$emit("clearSearch");
        }
      },
    },
  },
  methods: {
    clearSearch() {
      this.searchText = "";
      this.$emit("clearSearch");
    },
    async search() {
      try {
        if (this.searchText.length <= 2) {
          this.showToast(
            "warning",
            "top-right",
            "Warning",
            "AlertTriangleIcon",
            "Please enter at least 3 characters"
          );
          return;
        }
        this.addPreloader();

        const params = {
          message: this.searchText,
          user_id: this.currentUser.user_id,
        };

        if (this.searchInCurrentChat === true) {
          params.binnacle_id = this.G_CURRENT_CHAT_ID;
        }
        const response = await BinnacleService.showBinnacleMessages(params);
        this.$emit("search", response);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script> <style lang="scss" scoped>
.chat-search-bar {
  width: 100%;
  padding: 0px !important;
  margin: 0px !important;
  .without-left-border {
    border-left: none !important;
  }
  .br-left-5 > span {
    border-top-left-radius: 35px !important;
    border-bottom-left-radius: 35px !important;
  }
  .br-right-5 {
    border-top-right-radius: 35px !important;
    border-bottom-right-radius: 35px !important;
  }
  .bg-dark-gray {
    background-color: #f2f2f2 !important;
  }
  .placeholder-gray::placeholder {
    color: #9a9a9a !important;
    font-weight: 600 !important;
  }
}
.dark-layout {
  .chat-search-bar {
    .bg-dark-gray {
      background-color: #040d19 !important;
    }
  }
}
</style>