import { amgApi } from '@/service/axios';

class TransactionBinnacle { 

  async getBinnacleFiles(params) {
    try {
      const { data } = await amgApi.post("/commons/transaction-binnacle/files/index", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async addMember(params) {
    try {
      const { data } = await amgApi.post("/commons/transaction-binnacle/members/store", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async searchMember(params) {
    try {
      const { data } = await amgApi.post("/commons/transaction-binnacle/members/show", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async removeMember(params) {
    try {
      const { data } = await amgApi.post("/commons/transaction-binnacle/members/destroy", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async showBinnacleMessages(params) {
    try {
      const { data } = await amgApi.post("/commons/transaction-binnacle/messages/show", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async storeBinnacleMessageSeen(params) {
    try {
      const { data } = await amgApi.post("/commons/transaction-binnacle/messages/seen/store",params);
      return data;
    } catch (error) {
      throw error;
    }
  }

}

export default new TransactionBinnacle();
