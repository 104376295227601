<template>
  <div
    class="d-flex align-items-center justify-content-between chat-header p-2" 
  >
    <div class="d-flex align-items-center">
      <b-avatar
        size="4rem" 
        class="mr-1"
      ></b-avatar>
      <div class="d-flex flex-column">
        <span class="current-chat-name">
            {{ G_CURRENT_CHAT_HEADER.transaction_client }}
        </span>
      </div>
    </div>

    <div class="d-flex align-items-center">
      <div class="p-1 chat-header-icon" @click="toggleSidebar">
        <DocumentsSvg />
      </div> 
      <div
        v-if="isCeo"
        class="p-1 chat-header-icon"
        id="add-participant"
        href="#"
        tabindex="0"
      >
        <UsersSvg />

        <b-popover
          boundary="viewport"
          placement="topright"
          :target="'add-participant'"
          title="Search participants"
          variant="primary"
          triggers="focus"
        >
          <SearchParticipants
            :text="participantName"
            @userSelected="addMemberToChat"
          />
        </b-popover>
      </div>
      <div class="p-1 chat-header-icon" @click="$emit('toggleSearchSidebar')">
        <SearchSvg />
      </div>
    </div>
  </div>
</template>
<script>
import SearchSvg from "@/assets/images/icons/request-chat/search.vue";
import UsersSvg from "@/assets/images/icons/request-chat/users.vue";
import SearchParticipants from "@/views/commons/components/reports/binnacle/SearchParticipants.vue";
import DocumentsSvg from "@/assets/images/icons/request-chat/documents.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import BinnacleService from "@/views/commons/components/reports/services/transaction-binnacle.service.js";

export default {
  name: "ChatNavbar",
  components: {
    SearchSvg,
    UsersSvg,
    SearchParticipants,
    DocumentsSvg,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_CURRENT_CHAT_ID: "ChatReportStore/G_CURRENT_CHAT_ID",
      G_CURRENT_CHAT_HEADER: "ChatReportStore/G_CURRENT_CHAT_HEADER",
      G_CURRENT_CHAT_MEMBERS:
        "ChatReportStore/G_CURRENT_CHAT_MEMBERS",
    }),
  },
  data() {
    return {
      participantName: "",
    };
  },
  methods: {
    ...mapActions({
      A_GET_CHAT_MEMBERS: "ChatReportStore/A_GET_CHAT_MEMBERS",
    }),
    ...mapMutations({
      M_SET_CURRENT_CHAT_ID: "ChatReportStore/M_SET_CURRENT_CHAT_ID",
      M_ADD_MEMBER: "ChatReportStore/M_ADD_MEMBER",
    }),

    toggleSidebar() {
      this.$emit("toggleSidebar");
    }, 

    userIsInChat(user_id) { 
      return this.G_CURRENT_CHAT_MEMBERS.some(
        (participant) => participant.user_id === user_id
      );
    },
    getTotal(total_messages) {
      let total = 0;
      total_messages.forEach((element) => {
        total += element.pending_messages;
      });
      return total;
    },
    async addMemberToChat(user) {
      try {
        if (this.userIsInChat(user.id)) {
          this.showGenericToast({
            title: "Error",
            text: "User already added",
            variant: "danger",
          });
        } else {
          this.addPreloader();
          const params = {
            user_id: user.id,
            transaction_binnacle_id: this.G_CURRENT_CHAT_ID,
          };
          const { data } = await BinnacleService.addMember(params);

          this.showGenericToast({
            title: "Success",
            text: "User added successfully",
            variant: "success",
          });
          await this.A_GET_CHAT_MEMBERS({
            binnacle_id: this.G_CURRENT_CHAT_ID,
          });
          //this.M_ADD_MEMBER(data);
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.chat-header {
  border-bottom: 2px solid #d7d7d7;
  max-height: 10vh;
  position: relative !important;
  .current-chat-name {
    font-weight: 800;
    font-size: 1.2rem;
    color: #626262;
  }
  .custom-padding {
    padding: 0.5rem 0rem;
  }
  .chat-header-icon {
    background-color: #d7d7d7;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 0.2rem;
  }
}
.dark-layout {
  .chat-header {
    background-color: #040d19;
    border-bottom: 2px solid #0b213f;
    .current-chat-name {
      color: #fff;
    }
    .chat-header-icon {
      background-color: rgba(255, 255, 255, 0.03);
    }
    .popover-target-1 {
      background-color: rgba(94, 234, 212, 0.74) !important;
    }
  }
}
</style>