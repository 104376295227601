<template>
  <div class="chat-sidebar">
    <ChatSearchbar
      @search="searchMessage"
      @clearSearch="clearSearch"
      :searchInCurrentChat="true"
    />

    <MatchedMessagesList
      :matchedMessages="matchedMessages"
      @goToSelectedMessage="goToSelectedMessage"
    />
  </div>
</template>
<script>
import PerfectScrollbar from "vue-perfect-scrollbar";
import ChatSearchbar from "@/views/commons/components/reports/binnacle/ChatSearchBar.vue";
import MatchedMessagesList from "@/views/commons/components/reports/binnacle/MatchedMessagesList.vue";

export default {
  name: "ChatSearchSidebar",
  components: {
    PerfectScrollbar,
    ChatSearchbar,
    MatchedMessagesList,
  },
  data() {
    return {
      matchedMessages: [],
    };
  },
  methods: {
    clearSearch() {
      this.$emit("closeSearchSidebar");
    },
    searchMessage(matchedMessages) {
      this.matchedMessages = matchedMessages;
    },
    goToSelectedMessage(message) {
      this.$emit("goToSelectedMessage", message);
      this.clearSearch();
    },
  },

};
</script>

<style lang="scss" scoped>
.chat-sidebar {
  width: 30vw;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: #ffffff !important;
  border: 2px solid #d7d7d7;
  z-index: 999999999999; // 12
  transition: left 0.3s ease;
  overflow: scroll;
  overflow-x: hidden;

  .sidebar-title {
    font-weight: 600;
    font-size: 1.5rem;
    color: #4a4a4a;
  }

  .sidebar-left-text {
    font-weight: 700;
    font-size: 1.2rem;
    color: #4a4a4a;
  }

  .sidebar-right-text {
    font-weight: 600;
    font-size: 1.2rem;
  }
  .custom-scrollbar-files {
    width: 100%;
    height: 100%;
  }

  .sidebar-image {
    border-radius: 3%;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 15px rgba(0, 0, 255, 0.5);
    }
  }

  .bg-file {
    cursor: pointer;
    &:hover {
      background-color: rgba(233, 236, 239, 0.3);
    }
  }
}
.dark-layout {
  .chat-sidebar {
    background: #040d19 !important;
    border: 2px solid #0b213f;

    .sidebar-title {
      color: #fff;
    }

    .sidebar-left-text {
      color: rgba(255, 255, 255, 0.67);
    }
  }
}
</style>