<template>
  <div
    class="chat-footer d-flex align-items-center py-1 justify-content-between"
  >
    <div
      v-if="!isRecording"
      class="emoji-container"
    >
      <!-- Input Emojis -->
      <div
        class="cursor-pointer"
        @click="showEmoji = !showEmoji"
      >
        <emoji-svg />
      </div>

      <emoji-selector
        v-if="showEmoji"
        class="emoji-selector"
        @addEmoji="(character) => addEmoji(character)"
      />
    </div>

    <!-- Input Files -->
    <div
      v-if="!isRecording"
      class="emoji-attachments cursor-pointer"
      @click="attachFiles()"
    >
      <attachments-svg />
      <input
        ref="files"
        type="file"
        :accept="acceptedFileTypes"
        class="d-none"
        multiple
        @change="onFileChange"
      >
    </div>

    <b-form-textarea
      v-if="!isRecording"
      v-model="message"
      style="position: relative; width: 100%"
      class="chat-textarea"
      placeholder="Enter something..."
      no-resize
      :disabled="isRecording"
      @keydown.enter="onEnterKey"
      @keydown.enter.shift.exact.prevent="message += '\n'"
    />

    <div
      class="d-flex"
      :class="isRecording ? 'footer-audio chat-audio form-control' : ''"
    >
      <feather-icon
        v-if="isRecording"
        icon="TrashIcon"
        size="20"
        class="text-danger cursor-pointer"
        style="right: 0"
        @click="cancelRecording"
      />

      <div
        v-if="isRecording"
        class="pl-2"
        style="font-size: 1.2rem; right: 0"
      >
        {{ formattedTime }}
      </div>

      <div
        id="waveform"
        :class="!isRecording ? 'd-none' : ''"
        class="align-self-center pl-2"
        style="
          width:90%
          right: 0;
        "
      />
    </div>

    <!-- Input Audio -->
    <div class="cursor-pointer send-message-container">
      <!-- <div
        v-if="!isRecording && message == '' && files.length == 0"
        @click="toggleRecording"
      >
        <feather-icon
          icon="MicIcon"
          size="20"
        />
      </div> -->

      <div
        v-if="
          (!isRecording && message != '') || isRecording || files.length > 0
        "
        :disabled="isSending"
        @click="sendMessage()"
      >
        <send-svg />
      </div>
    </div>
  </div>
</template>

<script>
import AttachmentsSvg from '@/assets/images/icons/request-chat/attachment.vue';
import SendSvg from '@/assets/images/icons/request-chat/send.vue';
import EmojiSvg from '@/assets/images/icons/request-chat/emoji.vue';
import WaveSurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js';
import EmojiSelector from '@/views/commons/components/customer-tickets/chat-components/EmojiSelector.vue';
import { mapGetters } from 'vuex';
import ChatTransactions from '@/views/commons/components/reports/services/chat-transactions.service';

export default {
  components: {
    AttachmentsSvg,
    SendSvg,
    EmojiSvg,
    EmojiSelector,
  },
  props: {
    transactionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showEmoji: false,
      acceptedFileTypes:
        'image/*, audio/*, video/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx',
      message: '',
      files: [],
      audio: null,
      record: null,
      isRecording: false,
      timerInterval: null,
      wavesurfer: null,
      time: 0,
      isSending: false,
      isCancel: false,
    };
  },
  async created() {
    await this.createWaveSurfer();
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor((this.time % 3600000) / 60000);
      const seconds = Math.floor((this.time % 60000) / 1000);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
      G_CURRENT_CHAT_ID: 'ChatReportStore/G_CURRENT_CHAT_ID',
    }),
  },
  methods: {
    onEnterKey(event) {
      if (!event.shiftKey) {
        event.preventDefault();
        this.sendMessage();
      }
    },
    hideEmojis() {
      this.showEmoji = false;
    },
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.time += 1000;
      }, 1000);
    },
    async createWaveSurfer() {
      if (this.wavesurfer) {
        this.wavesurfer.destroy();
      }
      this.$nextTick(() => {
        this.wavesurfer = WaveSurfer.create({
          container: '#waveform',
          waveColor: '#99989A',
          progressColor: '#0077E6',
          barWidth: 3,
          barRadius: 3,
          cursorWidth: 0,
          height: 20,
          barGap: 3,
          backend: 'MediaElement',
          minPxPerSec: 5,
          normalizeToZero: true,
          waveOutlineWidth: 0,
          barMinHeight: 2,
        });

        this.record = this.wavesurfer.registerPlugin(
          RecordPlugin.create({
            scrollingWaveform: false,
            renderRecordedAudio: false,
          }),
        );
        this.record.on('record-end', async blob => {
          this.audio = blob;
          this.isRecording = false;
          clearInterval(this.timerInterval);
          this.wavesurfer.empty();
          await this.storeBinnacleMessage();
        });
      });
    },
    addEmoji(character) {
      this.message += character;
    },
    attachFiles() {
      this.$refs.files.click();
    },
    onFileChange(e) {
      const fileArray = Array.from(e.target.files);
      const newFileArray = [];

      fileArray.forEach(file => {
        const imageUrl = URL.createObjectURL(file);

        const newFile = {
          file,
        };
        file.url = imageUrl;
        newFileArray.push(newFile);
      });
      this.files = newFileArray;
      this.$emit('onFileChange', newFileArray);
    },
    toggleRecording() {
      this.isCancel = false;
      this.time = 0;

      if (this.isRecording) {
        this.record.stopRecording();
        return;
      }

      this.wavesurfer.empty();
      this.record.startRecording().then(() => {
        this.isRecording = true;
        this.startTimer();
      });
    },
    cancelRecording() {
      this.isCancel = true;
      this.isRecording = false;
      this.record.stopRecording();
      clearInterval(this.timerInterval);
      this.refreshWaveSurfer();
    },
    refreshWaveSurfer() {
      this.wavesurfer.destroy();
      this.createWaveSurfer();
    },
    async sendMessage() {
      this.isSending = true;

      if (!this.isRecording) {
        if (
          this.message == ''
          && this.files.length == 0
          && this.message.trim() == ''
        ) {
          this.isSending = false;
          return;
        }
        if (this.files.length > 0) {
          this.addPreloader();
        }
        await this.storeBinnacleMessage();
        this.removePreloader();
      } else {
        this.record.stopRecording();
        this.isRecording = false;
        clearInterval(this.timerInterval);
        this.wavesurfer.empty();
        // await this.storeBinnacleMessage();
      }
    },
    async storeBinnacleMessage() {
      if (this.isCancel == true) {
        this.isCancel = false;
        this.audio = null;
        return;
      }
      try {
        const form = new FormData();
        form.append('transaction_id', this.transactionId);
        form.append('created_by', this.currentUser.user_id);
        form.append('message', this.message);
        form.append('audio', this.audio);

        if (this.files.length > 0) {
          this.files.forEach(({ file }) => {
            form.append('files[]', file);
          });
        }

        this.message = '';
        await ChatTransactions.createTransactionMessages(form);
        this.files = [];
        this.audio = null;
        this.refreshWaveSurfer();
        this.hideEmojis();
        this.$emit('onNewMessage');
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.isSending = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-footer {
  position: relative;
  bottom: 0;
  width: 100%;
  border-top: 2px solid #d7d7d7;
  padding: 0 1rem;

  .emoji-container {
    position: absolute;
    left: 4.2rem;
    z-index: 1;
  }
  .emoji-attachments {
    position: absolute;
    left: 1.7rem;
    z-index: 1;
  }
  .send-message-container {
    position: absolute;
    right: 1.7rem;
    z-index: 1;
  }
  .emoji-selector {
    position: absolute !important;
    top: -20em;
    z-index: 99999999999; // 11
  }
  .chat-textarea {
    display: inline-block !important;
    height: 3.5em !important;
    line-height: 1.5 !important;
    padding: 1rem 1rem 1rem 6rem !important;
    overflow: hidden !important;
    border-radius: 0.5rem !important;
    background-color: #ffffff !important;
    &:focus,
    &:active,
    &:hover {
      border: 2px solid #14b8a6 !important;
    }
  }
  .chat-audio {
    border-radius: 0.5rem !important;
    background-color: #ffffff !important;
    height: 3.5em !important;
    &:hover {
      border: 2px solid #14b8a6 !important;
    }
  }
  .footer-audio {
    padding: 1rem !important;
  }
}
.dark-layout {
  .chat-footer {
    background-color: #040d19;
    border-top: 2px solid #0b213f;

    .chat-textarea {
      background-color: rgba(255, 255, 255, 0.03) !important;
      color: rgba(255, 255, 255, 0.87) !important;
      border-color: #0b213f !important;

      &:focus,
      &:active,
      &:hover {
        border: 2px solid #5eead4 !important;
      }
    }
    .chat-audio {
      border-color: #0b213f !important;
      background-color: rgba(255, 255, 255, 0.03) !important;
      color: rgba(255, 255, 255, 0.87) !important ;
      &:hover {
        border: 2px solid #5eead4 !important;
      }
    }
    .footer-audio {
      padding: 1rem !important;
    }
  }
}
</style>
